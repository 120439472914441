.user-image {
  vertical-align: middle;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
}

.update-toggle {
  color: #6495ed !important;
  font-weight: 700;
}

.update-toggle:hover {
  color: skyblue !important;
  text-decoration: underline !important;
  cursor: pointer;
}
