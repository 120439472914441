.status-centered{
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.status-centered-absolute{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.small-status-div {
  width: 100%;
  /* height: 5rem;
  max-height: 5rem; */
  overflow: hidden;
}

.small-status-div img {
  max-width: 100%;
  max-height: 100%;
}

.small-status-main-text {
  color: darkgray;
  font-weight: 700;
  font-size: 1.3em;
}

.small-status-small-text {
  color: darkgray;
  font-weight: 550;
  font-size: 0.9em;
}
