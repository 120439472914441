.heading {
    font-weight: 700;
    font-size: 14px;
    color: black;
    font-family: 'Livvic', sans-serif;
}

.text {
    font-size: 12px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

.table-text {
    font-size: 11px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 95%;
}

#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: black;
    font-size: 11px;
    font-weight: bold;
}

.paddingB {
    padding-bottom: -10px !important;
}

.gridItem {
    padding-left: 50px !important;
}

.heading {
    font-weight: bold !important;
    font-size: 1.1rem !important;
    margin-bottom: 8px !important;
}

.text {
    font-size: 1rem !important;
    color: #555 !important;
}

.paddingB {
    padding-bottom: 8px !important;
}