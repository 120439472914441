.chat-content {
  max-height: calc(100vh - 9rem);
  position: relative;
  height: 100%;
  margin: 10px;
}

.main-chat-content {
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 7rem) !important;
  height: 100%;
}

.content-header {
  padding: 1rem;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid #b0b0b0;
}

.current-chatting-user {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}

.content-body {
  height: calc(100vh - 20rem);
  width: 100%;
  /* max-width: calc(150vw - calc(150vw / 2)); */
  overflow: auto;
}
.chat__item {
  display: flex;
  margin-right: 0px !important;
  margin-left: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.2s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.chat__item .avatar {
  margin-right: 20px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}
.chat__item__content {
  background-color: #4462ff;
  color: #fff;
  padding: 15px;
  border-radius: 20px 20px 0 20px;
  max-width: 50%;
  min-width: 10%;
  margin-right: 0.7em;
}
.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
.chat__item__content .chat__meta span {
  font-size: 0.7rem;
  color: rgb(212, 209, 209);
  float: right !important;
}
.chat__item__content .chat__msg {
  font-size: 0.9rem;
}

.chat__item.buyer {
  flex-direction: row-reverse;
  transform-origin: left;
}
.chat__item.buyer .chat__item__content {
  background-color: rgb(242, 241, 241);
  color: #000;
  border-radius: 20px 20px 20px 0;
  max-width: 50%;
  margin-left: 0.7em;
}
.chat__item.buyer .avatar {
  margin-right: 20px;
  margin-left: 0px;
}
.chat__item.buyer .chat__item__content .chat__meta span {
  color: #d1d1d1;
  font-size: 0.7rem;
}
.content-footer {
  background-color: #f4f3f8;
  padding: 1em 2em;
  width: 100%;
}
.newmessage-footer {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
}
.newmessage-footer button {
  width: 5%;
  height: 5%;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2vw;
  color: #4665ff;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.newmessage-footer button:hover {
  transform: scale(1.2);
}
.newmessage-footer button i {
  display: block;
}
.newmessage-footer input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
  overflow-wrap: break-word;
}
#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}
.catalogue-name {
  font-size: 1rem;
}
.catalogue-last-active {
  font-size: 0.7rem;
  font-weight: 400;
  color: grey;
}

.system-notification {
  font-size: 0.8em;
}
