.cat-title {
  color: rgb(50, 49, 49);
  font-weight: bold;
  font-size: 1rem;
}

.cat-sub-title {
  color: rgb(109, 107, 107);
  font-size: 0.8rem;
}
