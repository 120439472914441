.status-container {
   position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.is-error-message {
  color: rgb(209, 10, 10);
}

.is-info-message {
  color: #6495ed;
}

.main-message{
    color: rgb(116, 116, 116);
}
