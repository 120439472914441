.users-list {
  margin-right: 10px !important;
  max-height: calc(100vh - 14rem);
  height: 100%;
  margin: 10px;
}

.message-header-title {
  font-weight: 500;
  padding-left: 10px;
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}

.chatlist__item:hover {
  background-color: rgb(212, 212, 212);
}

.chatlist__item.active {
  background: rgb(183, 179, 179);
  border-radius: 10px;
}

.input-group-text {
  background-color: #fff;
}
