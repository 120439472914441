.hover-pointer:hover {
  cursor: pointer;
}

.image-icon {
  height: 60px;
}

.nav-item.dropdown {
  list-style: none;
}

.MuiGrid-item{
  max-width: 100% !important;
}

.sticky-column {
  position: sticky !important;
  left: 0  !important;
  width:100px;
  min-width: 100%;
  background: white;
  z-index: 999 !important;
    word-wrap: break-word;
}
.row-color{
  color:white !important;
  background-color: #3c4b64 !important;
}