.arrow-icons {
  margin-left: 0.2em;
  font-size: 1.1rem;
  margin-right: 0.2em;
}

.button-font-size {
  font-size: 1.1rem !important;
}

.remove-btn-class {
  border: none !important;
  line-height: 1rem !important;
  --cui-btn-hover-color: #6495ed !important;
  --cui-btn-hover-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-hover-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-color: #6495ed;
  --cui-btn-disabled-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-shadow: var(--cui-btn-bg, transparent) !important;
}

.remove-btn-class:hover {
  text-decoration: underline !important;
}

.loading-icon {
  margin-left: 0.2em !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
  margin-right: 0.2em !important;
}

.upload-btn-hide {
  display: none;
}

.upload-btn-show {
  display: block;
}
