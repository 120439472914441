* {
  box-sizing: border-box;
}

.card-tile {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  max-width: 14rem;
  height: 15rem;
}

#pro-cards {
  background-color: #E6E7E8;
  margin-bottom: 10px;
}

#view-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

#edit-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  margin: 10px;
  position: absolute;
  top: 45px;
  right: 0;
}

#delete-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  margin: 10px;
  position: absolute;
  top: 90px;
  right: 0;
}

#below-card {
  padding-bottom: 40px;
}

.profile {
  border-radius: 10px;
  transition: 3s all ease-in-out;
  height: 12rem;
  height: 100%;
  padding: 20px;
}

.hover-image {
  background-size: cover;
  object-fit: cover;
  transition: 3s all ease-in-out;
}

.export-pis-btn {
  background-color: #3E454A !important;
  color: #fff !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  width: auto !important;
  height: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #3E454A !important;
  /* padding: 0 12px !important; */
  display: flex !important;
  align-items: center !important;
}

.export-pis-btn .MuiSelect-select {
  padding: 0;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-17ffvgn-MuiTypography-root {
  padding: 0px !important;
}

.export-pis-btn .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* Style the select icon to be white */
.export-pis-btn .MuiSelect-icon {
  color: #fff !important;
}

.export-pis-btn-select {
  color: #3E454A !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 30px !important;
  padding: 0 10px;
  border: 1px solid #3E454A;
  border-radius: 4px;
  background-color: transparent;
}

.export-pis-btn-border {
  color: #3E454A !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #3E454A !important;
}

.card-tile-content {
  font-weight: 700;
}

.card-tile-para {
  font-size: 11px;
  background-color: #E6E7E8;
  width: 65%;
  margin: auto;
}

.card-tile:hover .profile {
  /* transform: scale(1.4); */
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.item-title {
  font-size: 1em;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-name {
  font-size: 1.2em;
  font-weight: 900;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
}

.product-code {
  font-size: 0.9em;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
  align-items: center;
}

.card-tile .content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.card-tile .content .back {
  position: absolute;
  width: 100%;
  background-color: #f1f1f1e6;
  transition: 1s;
  height: 100%;
  max-height: 21rem;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
  padding: 5px;
}

.from-left {
  top: 0;
  left: -100%;
}

.card-tile:hover .content .from-left {
  left: 0%;
}

.from-bottom {
  top: 100%;
  left: 0;
}

.card-tile:hover .content .from-bottom {
  top: 0%;
}

.from-right {
  top: 0%;
  right: -100%;
}

.card-tile:hover .content .from-right {
  right: 0;
}

.card-tile .content .back h3 {
  font-size: 15px;
  letter-spacing: 2px;
}

.card-tile .content .back .tem-img {
  border-radius: 100%;
  height: 2em;
  width: 2em;
}

.card-tile .content .back .des {
  font-size: 13px;
}

.more-info-div {
  position: absolute;
  bottom: 24px;
}

.more-info-btn {
  background-color: #fff;
  border: none;
  font-weight: 600;
  padding: 5px 20px;
}

.card-tile .content .back .social-icon li {
  display: inline-block;
}

.card-tile-actions {
  display: block;
  width: 100%;
}

.front {
  height: 100%;
}

.front-effect-checked {
  color: aliceblue;
}

.item-title-checked {
  color: rgb(247, 247, 247);
  display: flex;
  justify-content: flex-end;
}

.for-checkbox {
  display: flex;
  justify-content: flex-end;
}