.boxed-widget {
  border-radius: 15px;
  padding: 2em 1em;
  transform: translate3d(0, 0, 0);
  z-index: 90;
  position: relative;
  /*  border: 1px solid #ddd; */
  background-color: #eef1fa;
}

.boxed-widget h3 {
  font-size: 20px;
  padding: 0 0 15px;
  display: block;
}

.card-body {
  padding: 0.8rem !important;
}

.card-heading-text {
  color: gray;
}

.card-footer-text {
  font-weight: 300;
  color: black;
  text-align: justify;
}
