.full-page-chat {
  display: flex; /* Use flex to layout chat list and chat body side by side */
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  border: 1px solid rgb(182, 181, 181);
  margin: 0; /* Remove margin */
}

.chat-users-list {
  border-right: 1px solid rgb(182, 181, 181);
  overflow-y: auto; /* Allow scrolling if needed */
  height: 100%; /* Ensure full height */
}

.full-page-chat .form-control {
  border-color: transparent;
  border-radius: 0;
}

.full-page-chat .form-control:focus {
  border-color: transparent;
  box-shadow: inset 0px 0px 0px 1px transparent;
  border-radius: 0;
  border-bottom: 2px solid #5189f1;
}

.col-xs-12,
.col-sm-12,
.col-md-6,
.col-lg-5,
.col-lg-7,
.col-xl-4,
.col-xl-8 {
  padding: 0; /* Remove padding for a clean layout */
}

.chat-body {
  overflow-y: auto; /* Allow scrolling for chat messages */
  height: 100%; /* Ensure full height */
}
