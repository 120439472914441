.image-column {
  padding-left: 2%;
}

.product-image {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-wrap: normal;
}

.product-image {
  box-sizing: border-box;
  max-width: 100%;
}

.smImage {
  max-width: 30%;
  margin-right: 3%;
}

/* crasual */

.carousel {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.carousel__images {
  display: flex;
  width: 70%;
  overflow-x: hidden;
  position: relative;
  gap: 0.5rem;
}

.carousel__image {
  margin: auto;
  height: 10vmin;
  min-width: 40%;
  border: 0.2rem solid #ffa70010;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel__image-selected {
  border: 0.1rem solid rgb(255, 166, 0);
}

.carousel__button {
  position: absolute;
  top: 40%;
}

.carousel__button-left {
  left: 5%;
}

.carousel__button-right {
  right: 5%;
}

.product-attributes-container{
  padding: 1rem;
}

.product-attribute{
  padding-bottom: 0.8rem;
}

.pac-name{
  font-size:2.3rem;
  color: #0f0e0eb3;
  font-weight: 550;
}

.pac-code{
  font-size:1rem;
  color: #0f0e0eb3;
  font-weight: 450;
}

.detail-title{
  font-size:0.9rem;
  color: black !important;
  font-weight: 500;
}
.detail-info{
  font-size:0.9rem;
  color: #000000;
  font-weight: 450;
}