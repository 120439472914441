.user-card {
  padding: 1.5rem;
  width: 100% !important;
}

.user-card:hover {
  transform: scale(1.05) !important;
}

.user-title {
  color: rgb(83, 82, 82);
  font-weight: 500;
}

.user-email {
  color: rgb(104, 101, 101);
  font-weight: 500;
}

.role-container {
  display: flex;
  flex-direction: row;
}

.role-image {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 50px;
  height: 50px;
}

.role-text {
  margin-left: 1rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.user-role {
  padding-top: 0.4em;
  font-size: 14px;
  color: rgb(42, 44, 44);
  font-weight: 600;
}

.user-role-desc {
  margin-bottom: 1rem;
  color: rgb(95, 97, 96);
  font-size: 14px;
}
