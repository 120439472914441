.active-chat {
  border-left: 3px solid green;
}

.user-name {
  font-size: 0.9rem;
  color: #5189f1;
}

.badge-dot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 2.9rem;
  margin-top: -0.75rem;
}
