.system-notification-msg {
  align-items: center;
  clear: both;
  display: flex;
  letter-spacing: 1px;
  margin: 8px 0;
}
.system-notification-msg::after,
.system-notification-msg::before {
  display: block;
  content: '';
  flex-grow: 1;
  border-top: 1px solid lightgrey;
}

.system-notification-msg::before {
  margin-right: 12px;
}

.system-notification-msg::after {
  margin-left: 12px;
}
