.heading {
    font-weight: 700;
    font-size: 14px;
    color: black;
    font-family: 'Livvic', sans-serif;
}

.text {
    font-size: 12px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

.table-text {
    font-size: 11px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 95%;
}

#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
    color: #03060f;
    font-family: 'Livvic', sans-serif;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: black;
    font-size: 11px;
    font-weight: bold;
}

.paddingB {
    padding-bottom: -10px !important;
}

.gridItem {
    padding-left: 50px !important;
}

.heading {
    font-weight: bold !important;
    font-size: 1.1rem !important;
    margin-bottom: 8px !important;
}

.text {
    font-size: 1rem !important;
    color: #555 !important;
}

.paddingB {
    padding-bottom: 8px !important;
}

.pdf-page {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h6 {
    margin-bottom: 10px;
    border-bottom: 2px solid #007782;
    padding-bottom: 10px;
}

.box {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

strong {
    color: #333;
}

.header_top {
    color: #007782;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.po_details {
    margin-bottom: 20px;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.table_product {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table_header {
    color: #af7f29 !important;
    /* background-color: #e0e0e0 !important; */
    font-weight: bold !important;
    /* border: 1px solid #000000 !important; */
    padding: 6px 10px !important;
    word-wrap: break-word !important;
    /* Allow text wrapping in headers */
}

.table_cell {
    /* border: 1px solid #000000 !important; */
    padding: 6px 10px !important;
}

.table_cell_last {
    /* border: 1px solid #000000 !important; */
    padding: 6px 10px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
}

.table_cell_link {
    color: black;
    text-decoration: none;
    padding-left: 0 !important;
    /* font-weight: bold; */
}

.product_heading {
    font-weight: bold;
    color: #007782;
    padding-left: 10px;
}

.data_key {
    font-weight: bold !important;
    color: #b28733;
}

.top_div {
    width: 100%;
    /* background-color: #007782; */
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-top: 0;
}

.pdf_div {
    box-sizing: border-box;
    flex-grow: 1;
    padding-bottom: 20px;
    /* margin-top: 20px; */
}

.footer_link {
    text-decoration: none;
}

footer {
    width: 100%;
    margin-top: auto;
    /* Ensure the footer sticks to the bottom */
    border-top: 1px solid black;
    text-align: center;
}

.inner_table {
    width: 100%;
    table-layout: fixed;
}