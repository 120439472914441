.custom-card {
  padding: 32px;
  padding: 0 !important;
  height: 80% !important;
  transform: translate3d(0, 0, 0) !important;
  z-index: 90;
  position: relative;
  background-color: #f7f7f7 !important;
  border-radius: 15px !important;
  border-color: #eef1fa !important;
}

.custom-card:hover {
  /* box-shadow: 5px 10px #d1cbcb; */
  transform: scale(1.1) !important;
  /* background-color: #fcf1f1 !important; */
}

.card-body {
  padding: 1rem !important;
}

.card-heading-text {
  color: rgb(43, 41, 41) !important;
}

.card-footer-text {
  font-weight: 300;
  color: rgb(75, 73, 73);
}

.fa-style {
  color: #f9b115;
  padding-top: 0.4rem !important;
  padding-bottom: 0.6rem !important;
}
