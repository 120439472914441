.footer {
  display: block !important;
}

.footer-brand {
  text-align: center;
}

.footer-brand-white {
  background-color: white !important;
  text-align: center;
}

.footer-url {
  color: #6495ed;
  text-decoration: none;
}
.footer-url:hover {
  color: #ffc221;
  text-decoration: none;
}
