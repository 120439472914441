.sc-message {
  width: 100%;
  /* margin: 0 20px; */
  padding-bottom: 10px;
  display: flex;
  padding-right: 5px;
}

.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--content.received {
  justify-content: flex-start;
}

.sc-message--avatar {
  align-self: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-left: 15px;
}

.sc-message--text {
  padding: 5px 10px;
  border-radius: var(--cui-border-radius-lg) !important;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: 70%;
}
.sc-message--image {
  padding: 5px 10px;
  border-radius: var(--cui-border-radius-lg) !important;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: 50%;
}
.pdf--message{
  cursor: pointer;
}

.sc-message--content.sent .sc-message--text {
  color: rgb(226, 226, 226);
  background-color: #6495ed;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.sent .sc-message--image {
  color: rgb(226, 226, 226);
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #ddeff8;
  margin-right: 40px;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #cccdd1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc-message--file p {
  margin: 0 0 0 10px;
  color: rgb(86, 88, 103, 60%);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgb(86, 88, 103, 30%);
}

.sc-message--content.query {
  justify-content: flex-end;
}
.sc-message--content.query .sc-message--text {
  color: rgb(226, 226, 226);
  background-color: #2a2e4b;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.query .sc-message--image {
  color: rgb(226, 226, 226);
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
