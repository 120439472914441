body::-webkit-scrollbar {
  width:11px; 
}
body::-webkit-scrollbar-thumb {
  background-color: #3c4b64; /* Color of the scrollbar thumb */
  border-radius: 7px; /* Radius of the scrollbar thumb */
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #3c4b64; /* Hover color */
}

.page-spacing {
  padding: 10px !important;

  @media all and (max-width: 991px) {
    padding: 20px 15px;
  }

  @media all and (max-width: 767px) {
    padding-inline: 15px;
  }
}
.header-color{
    background: #3c4b64;
    color: white !important;
}

.dropdown-menu {
  .notification-text {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  p {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

.notification-hover {
  p {
    font-size: 15px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  :hover {
    background-color: #edf3ff !important;
  }
}

.dropdown-menu {
  inset: auto !important;
  top: auto !important;
  left: auto !important;
  right: 1px !important;
  bottom: auto !important;
  margin: 20px, 0px, 0px, 0px !important;
  transform: none !important;
  z-index: 1000;
}
.notification-dropdown {
  min-width: 250px;
  max-height: 500px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-right: 10px;

  &:before {
    position: absolute !important;
    content: '';
    top: -7px !important;
    right: 10px !important;
    width: 0 !important;
    height: 0 !important;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-bottom: 8px solid white !important;
    filter: drop-shadow(0 -1px 0 rgba(0, 0, 0, 0.12)) !important;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: #ffffff !important;
    color: rgb(3, 3, 3) !important;
  }
}

.notification-dropdown::-webkit-scrollbar {
  width: 8px;
}

.notification-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.card-spacing {
  padding: 30px 20px;
}

.common-card {
  background-color: white;
  border-top: 3px solid white;
  box-shadow: $shadow-md;

  .common-card-title {
    padding: 15px 20px;
  }

  .common-card-content {
    border-top: 1px solid $border-color;
    padding: 20px;

    @media all and (max-width: 575px) {
      padding-inline: 10px;
    }
  }
}

.content {
  background-color: #fafafa !important;
}

// NEW LAYOUT CSS
.advertisement-report::after {
  content: '•';
  color: #19b69b;
  font-size: 1.5em;
  margin-left: 5px;
}

.growth {
  background-color: #34c085;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.advertisement-graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advertisement-graph {
  width: 100% !important;
  height: auto !important;
}

.btn-theme {
  width: 40px !important;
  height: 38px !important;
  background-color: $heading-color !important;
  border: 1px solid $heading-color !important;
  color: $white !important;
  box-shadow: none;

  &:hover {
    box-shadow: $shadow-md;
    background-color: $heading-color !important;
    border: 1px solid $heading-color !important;
    color: $white !important;
  }

  &:focus {
    color: $white !important;
  }
}

.file-input-container {
  position: relative;
  display: inline-block;
}
// input[type="file"] {
//   display: none;
// }
.custom-file-label {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s ease;
}
.custom-file-label:hover {
  background-color: #0056b3; /* Darker on hover */
}
.custom-file-label:active {
  background-color: #003f7f; /* Even darker when clicked */
}

.header.header-fixed {
  right: 0 !important;
  left: 0 !important;
}

.dashboard-page {
  background-color: #e6e7e8;
}

#dashboard-text {
  font-size: 30px;
  font-weight: 700;
  padding-left: 15px;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: #3e454a !important;
  color: rgb(255, 255, 255) !important;
}

.product-view-btn {
  background: black;
  color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: bold;
  font-size: 20px;
}

.view-product-padding {
  padding-top: 0px;
}

#product-text {
  font-size: 30px;
  font-weight: 700;
  padding-left: 15px;
}

#dash-icon {
  color: #939598;
}

#padding-search {
  padding-top: 40px;
}

#product-margin {
  margin-left: 0px;
}

#pro-icon {
  color: #3e454a;
}

#search-input {
  background-color: #fff;
  padding-left: 10px;
  width: 40%;
}

.border-none {
  border-color: #fff;
}

.body {
  // background-color: white !important;
  background-color: #fafafa !important;
}

.wrapper {
  min-width: 375px;
  /* Suppose you want minimum width of 1000px */
  width: auto !important;
  /* Firefox will set width as auto */
}

.remove-link-props {
  text-decoration: none;
}

.grey-color {
  color: grey !important;
}

.pt-20 {
  padding-top: 20px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.remove-btn-class {
  border: none !important;
  line-height: 1rem !important;
  --cui-btn-hover-color: #6495ed !important;
  --cui-btn-hover-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-hover-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-color: #6495ed;
  --cui-btn-disabled-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-shadow: var(--cui-btn-bg, transparent) !important;
}

.get-hover-effect:hover {
  cursor: pointer;
  background-color: transparent;
}

.remove-btn-class:hover {
  text-decoration: underline !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.hover-effect:hover {
  cursor: pointer;
}

.tile-title {
  font-weight: 700;
}

.tile-details {
  font-weight: 600;
  color: grey;
}

.Target-root.Demo-chart.css-1ty56lv {
  height: 250px !important;
}

.Target-root.css-4dretl {
  width: 200px !important;
  height: 338px !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.one {
  background-color: #2ed391;
}

.two {
  background-color: #fced5c;
}

.three {
  background-color: #fba779;
}

.four {
  background-color: #ff6100;
}

.five {
  background-color: #8d8ea5;
}

.li-item {
  padding-left: 10px;
}

.list-group-item:hover {
  background-color: #e6e7e8;
  transition: 0.3 all ease-in-out;
}

.list-group-item {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.card-title-chart {
  background: #3c4b64;
  color: white;
  text-align: center;
  padding-top: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-btn {
  background: #6a6c6e;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  padding: 8px 15px;
}

.card-btn:hover {
  color: #fff !important;
}

.days-filter {
  border: none;
  background: #3e454a;
  color: white;
  font-weight: 600;
  margin-bottom: 10px;
}

.order-pending-btn {
  border: none;
  background: #fcab10;
  color: white;
  border-radius: 20px;
  padding: 4px 10px;
}

.vendors-border-right {
  border-right: 3px solid #d1d3d4;
  padding-right: 20px;
}

.fs-all {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.orders-table {
  border-collapse: collapse;
  width: 100%;
}

.table-th {
  text-align: left;
  padding: 8px;
}

.table-tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.MuiFormControl-root .MuiInputBase-root fieldset legend {
  float: unset !important;
}

.po-span {
  padding-left: 10px !important;
}

.progress {
  height: 12px !important;
  margin-bottom: 20px !important;
}

.progress-bar {
  border-radius: 20px !important;
}

.progress.bar {
  background-color: transparent !important;
  height: 29px !important;
  width: 100% !important;
  justify-content: flex-end;
}

.bg-progress-one {
  background-color: #6A8CAF;
}

.bg-progress-two {
  background-color: #F4978E;
}

.bg-progress-three {
  background-color: #A8BDA6;
}

.bg-progress-four {
  background-color: #D9C7A1;
}

.bg-progress-five {
  background-color: #E38B6D;
}

.bg-progress-six {
  background-color: #867838;
}

.control-head-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5px;
  color: #434343 !important;
}

.btn-primary {
  --cui-btn-hover-bg: #6495ed;
}

.custom-links {
  //color: linear-gradient(#0071dc, yellow) !important;
  background: -webkit-linear-gradient(#6f7bff, #f354ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none !important;
}

.custom-links:hover {
  color: #ffc107 !important;
  text-decoration: underline !important;
}

.bold-text {
  font-weight: 700 !important;
}

.heading-small {
  font-size: 15px;
  font-weight: 500;
  color: #767676;
}

.clickable-icon {
  cursor: pointer;
}

.justify-content-flexend {
  display: flex;
  justify-content: flex-end;
}

.fixed-nav {
  // background-color: var(--cui-body-color);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  margin-bottom: 0px;
}

.btn {
  line-height: 2 !important;
}

.btn-primary {
  background-color: #6495ed !important;
  border-color: #6495ed !important;
  color: #fff !important;

  --cui-btn-shadow: #6495ed !important;
}

.btn-primary:hover {
  background-color: #fff !important;
  border-color: #6495ed !important;
  color: #6495ed !important;
}

.inline-css {
  display: inline-block !important;
  margin-left: 0.3em !important;
}

.clickable-div {
  color: #6495ed;
}

.clickable-div:hover {
  color: #3476f1 !important;
  text-decoration: underline !important;
}

.notifications-icon {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.1em;
  color: #1565C0;
}

.notifications-icon:hover {
  color: rgb(75, 75, 251);
}

.notifications-icon:active {
  color: blue;
}

.border-radius-50 {
  border-radius: 50px;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }

  .container-max {
    max-width: 1300px !important;
    margin: 0 auto;
  }
}

.pl20 {
  padding-left: 20%;
}

.pl40 {
  padding-left: 40%;
}

.pl60 {
  padding-left: 60%;
}

.remove-margin {
  font-weight: 600 !important;
  margin: 0 !important;
}

.primary-hover {
  color: #ffc221;
}

.primary-hover:hover {
  color: #ffc221;
}

.custom-select-order fieldset {
  border: none !important;
}

.order-dataList {
  padding: 8px !important;
  text-align: left !important;
  background-color: #3c4b64 !important;
  color: #fff !important;
}

.order1-dataList {
  padding: 8px !important;
  text-align: left !important;
  background-color: #3c4b64 !important;
  color: #fff !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 20px !important;
}

.order-table {
  padding: 8px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.order-state {
  font-size: 0.8125rem;
  display: inline-flex;
  height: 32px;
  background-color: transparent;
  border: 1px solid rgba(46, 125, 50, 0.7);
  border-radius: 16px;
  margin-top: 26px;
  width: 150px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.order-import {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 20px;
}

.tracking_code {
  border: none;
  min-width: 200px;
  padding: 10px 30px;
  border-radius: 20px;
}

.tracking_code:hover {
  background-color: #dddbdb;
}

.order_one {
  display: block !important;
}

.order_two {
  background-color: #dddbdb;
  padding: 20px;
  font-weight: 400;
}

.order_three {
  padding-top: 20px;
  padding-left: 50px;
}

.order_four {
  font-size: 20px;
  font-weight: 500;
}

.order_five {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.order_six {
  padding-top: 30px;
}

.order_seven {
  display: flex;
  align-items: center;
}

.order_eight {
  margin-bottom: 0px;
  font-size: 16px;
}

.order_nine {
  margin-bottom: 0px;
  text-transform: capitalize;
}

.order_ten {
  border: 7px solid rgb(126 126 126);
  width: 100%;
}

.order_eleven {
  border: 2px solid #374253;
  border-left: none;
  border-right: none;
}

.order_twelve {
  padding: 12px;
  text-align: left;
  color: #374253;
  font-weight: bold;
}

.order_thirteen {
  padding: 8px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.order_fourteen {
  background-color: #4f4f4f;
  color: #fff;
  padding: 2px;
  border-radius: 20px;
  padding-right: 9px;
  padding-left: 9px;
  padding-bottom: 9px;
  text-transform: capitalize;
}

.order_fifteen {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
}

.dash_two {
  font-weight: bold;
  margin-bottom: 0px;
  padding-left: 10px;
  font-size: 35px;
  color: #222c33;
}

.dash_three {
  color: #747474;
  padding-left: 59px;
  margin-bottom: 0px !important;
}

.dash_four {
  display: flex;
  align-items: end;
}

.dash_five {
  background-color: #3c4b64 !important;
  color: #fff;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Calibri';
  font-size: 18px;
}

.dash_six {
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #222c33;
  font-family: 'Calibri';
}

.dash_seven {
  margin-top: 30px;
}

// .dash_eight {
//   background: #222C33;
//   color: white;
//   border: none;
//   padding-left: 44px;
//   padding-right: 44px;
//   padding-top: 15px;
//   padding-bottom: 15px;
//   font-family: 'Calibri';
// }
.dash_nine {
  background-color: #efefef;
  color: #747aff;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border: none;
}

.dash_ten {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #222c33;
}

.dash_eleven {
  padding-left: 30px;
}

.dash_twelve {
  font-size: 21px;
  font-weight: bold;
}

.dash_thirteen {
  font-size: 30px;
  color: #222c33;
  font-family: 'Calibri';
  letter-spacing: 2px;
}

.dash_fourteen {
  font-size: 18px;
  padding-left: 20px;
}

.dash_fifteen {
  background: #222c33;
  color: white;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: 'Calibri';
}

.dash_sixteen {
  padding-left: 8px;
  color: '#222C33';
  font-family: 'Calibri';
}

.apply-button {
  background-color: #3c4b64;
  color: white;
  border: none;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Calibri';
  width: 100%;
}

.apply-button:hover {
  background-color: #151718;
}

/* In your CSS file or styled components */
.unread-false {
  background-color: rgba(240, 240, 240, 0.8);
  /* Set the desired background color with opacity */
}

/* Add this to your stylesheet */
.circular-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  /* or any other color you prefer */
  border-radius: 50%;
  margin-right: 5px;
  /* Adjust the margin as needed */
}

.pagination-container {
  text-align: center;
  margin-top: 50px;

  span {
    margin-right: 10px;
  }

  .pagination-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s;

    &:disabled {
      color: #aaa;
      pointer-events: none;
    }

    &:hover:not(:disabled) {
      background: #f2f2f2;
    }

    &:not(:disabled) {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

.MuiModal-backdrop {
  // background-color: rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
  // position:relative !important
}

.MuiFormControl-root .MuiInputBase-root fieldset legend {
  float: unset !important;
}

.purchaseOrderLink {
  background-color: #3c4b64 !important;
  color: white !important;
}

.refresh_order {
  width: 100px !important;
  margin-right: 25px !important;
}

.order_table {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.modal-css {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  outline: none;
}

.link-router {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.link-router:hover {
  text-decoration: underline;
  color: inherit;
}

.reset-icon path {
  stroke: #1976d2;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  margin-top: 70px !important;
  font-weight: 600;
}

.dot-bounce {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin-left: 5px;
  /* Adjust spacing between dots */
  animation: bounce 0.5s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.user-card.user-card-disabled {
  opacity: 0.5;
  /* Reduce opacity to indicate disabled state */
}

.custom-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 1px solid black;
  pointer-events: none;
  z-index: 100;
  transform: translate(-50%, -100%);
}

.custom-tooltip .label {
  font-weight: bold;
}

.custom-tooltip .intro {
  margin-top: 5px;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f0f0f0;
}

.MuiTableHead-root {
  background-color: white;
  z-index: 2;
  position: sticky !important;
  top: 0;
  display: table-header-group;
}

// Handle scroll bar with design
.scroll-box {
  height: 600px !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  text-align: justify !important;
}

.scroll-box .MuiTableCell-stickyHeader {
  background-color: white;
  top: 0;
  z-index: 1;
}

.scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background: #a7a4a4;
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scroll-box::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.css-l0h214-MuiResponsiveChart-container {
  padding: 15px !important;
}
