/* Make the tag position relative to the figure */
.card-img-overlay {
  padding-left: 0 !important;
}

.custom-card-classes:hover {
  transform: scale(1.1) !important;
}

.save-btn {
  position: absolute !important;
  right: 6% !important;
  z-index: 9;
  bottom: 25%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.808);
  width: 40px;
  height: 40px;
  font-size: 17px;
  border-radius: 50%;
  line-height: 2.1;
  cursor: pointer;
  transition: 0.5s;
}

.shortlist-btn {
  position: absolute !important;
  right: 6% !important;
  z-index: 9;
  bottom: 25%;
  color: rgb(211, 211, 211);
  text-align: center;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.808);
  width: 90px;
  height: 30px;
  font-size: 17px;
  border-radius: 5%;
  line-height: 1.5;
  cursor: pointer;
  transition: 0.5s;
}

.shortlisted {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.808);
}

.tag-custom {
  font-size: 0.9em !important;
}

.product-code{
  word-wrap: break-word;
}
